<template>
  <div class="row">
    <div class="flex xs12">
      <h6>{{ $t('users.contacts.label') }}</h6>
    </div>
    <div
      class="flex xs12"
      v-for="(contact, index) of contacts"
      :key="contact.id"
    >
      <div class="row align-items-start justify-content-center">
        <div class="flex xs12 md5">
          <va-select
            v-model="contact.type"
            :label="$t('users.contacts.typeInput')"
            :options="contactTypes"
            :no-options-text="$t('layout.empty')"
            :disabled="loading"
            :loading="loading"
            text-by="name"
            searchable
          />
        </div>
        <div
          class="flex xs12 md2"
          v-show="isPhone(contact.type)"
        >
          <va-input
            color="info"
            v-model="callingCode"
            type="tel"
            :disabled="loading"
            :label="$t('users.contacts.callingCodeInput')"
          >
            <va-icon
              slot="prepend"
              color="gray"
              name="fa fa-plus"
            />
          </va-input>
        </div>
        <div
          class="flex xs12"
          :class="isPhone(contact.type) ? 'md4' : 'md6'"
        >
          <va-input
            color="info"
            v-model="contact.masked"
            :type="getType(contact.type)"
            :disabled="loading"
            :label="$t('users.contacts.valueInput')"
          />
        </div>
        <div class="flex xs12 md1">
          <va-popover
            :message="$t('users.contacts.remove')"
            placement="left"
          >
            <va-button
              flat
              small
              type="button"
              color="danger"
              icon="fa fa-times"
              @click.prevent="removeContact(index)"
            />
          </va-popover>
        </div>
      </div>
    </div>
    <div class="flex xs12">
      <va-button
        flat
        small
        type="button"
        color="primary"
        icon="fa fa-plus"
        @click.prevent="addContact"
      >
        {{ $t('users.contacts.add') }}
      </va-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'user-contacts',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    country: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      contacts: [],
      contactTypes: [
        {
          name: this.$t('users.contacts.types.email'),
          id: 0,
        },
        {
          name: this.$t('users.contacts.types.phone'),
          id: 1,
        },
        {
          name: this.$t('users.contacts.types.whatsapp'),
          id: 2,
        },
        {
          name: this.$t('users.contacts.types.facebook'),
          id: 3,
        },
        {
          name: this.$t('users.contacts.types.twitter'),
          id: 4,
        },
      ],
      valueMask: '',
      callingCode: '',
    }
  },
  computed: {
    phoneTranslations () {
      const translations = {
        countrySelectorLabel: this.$t('layout.form.phone.country_selector'),
        countrySelectorError: this.$t('layout.form.phone.country_selector_error'),
        phoneNumberLabel: this.$t('layout.form.phone.label'),
        example: this.$t('layout.form.phone.example'),
      }
      return translations
    },
  },
  watch: {
    user (val) {
      if (val.district) {
        if (val.district.country) {
          this.callingCode = val.district.country.calling_code || ''
        }
      } else if (val.country) {
        this.callingCode = val.country.calling_code || ''
      }
      if (val.user_contacts) {
        this.initializeUser(val.user_contacts)
      }
    },
    country (val) {
      if (val) {
        this.callingCode = val.calling_code || ''
      }
    },
    callingCode (val) {
      for (const contact of this.contacts) {
        let type = 0
        if (Number.isInteger(contact.type)) {
          type = contact.type
        } else if (contact.type) {
          type = contact.type.id
        }
        contact.type = this.contactTypes.find(c => c.id === type)
        contact.masked = this.isPhone(contact.type)
          ? this.updatePhone(contact, val)
          : contact.value
      }
    },
    '$store.currentLocale' (val) {
      this.getcontactTypes()
    },
  },
  created () {
    if (this.user && this.user.user_contacts) {
      this.$nextTick(() => {
        this.initializeUser(this.user.user_contacts)
      })
    }
  },
  methods: {
    getType (type) {
      if (!type) return 'text'

      let inputType = 'text'
      switch (type.id) {
        case 0:
          inputType = 'email'
          break
        case 1:
        case 2:
          inputType = 'tel'
          break
      }
      return inputType
    },
    getcontactTypes () {
      const types = [
        {
          name: this.$t('users.contacts.types.email'),
          id: 0,
        },
        {
          name: this.$t('users.contacts.types.phone'),
          id: 1,
        },
        {
          name: this.$t('users.contacts.types.whatsapp'),
          id: 2,
        },
        {
          name: this.$t('users.contacts.types.facebook'),
          id: 3,
        },
        {
          name: this.$t('users.contacts.types.twitter'),
          id: 4,
        },
      ]

      this.contactTypes = types.slice(0)
      return this.contactTypes
    },
    isPhone (type) {
      const value = type ? type.id : 0
      return value === 1 || value === 2
    },
    updatePhone (contact, callingCode = '') {
      if (!contact.value) return ''

      let length = callingCode.toString().length
      let index = contact.value.indexOf(callingCode)
      if (index === -1) {
        length = 0
        index = 0
      }
      return contact.value.substr(index + length)
    },
    initializeUser (contacts) {
      if (!Array.isArray(contacts)) return
      contacts = contacts.slice(0)
      if (this.contactTypes.length === 0) {
        this.getcontactTypes()
      }

      for (const contact of contacts) {
        let type = 0
        if (Number.isInteger(contact.type)) {
          type = contact.type
        } else if (contact.type) {
          type = contact.type.id
        }
        contact.type = this.contactTypes.find(c => c.id === type)
        if (!contact.type) {
          contact.type = this.contactTypes[0]
        }
        contact.masked = this.isPhone(contact.type)
          ? this.updatePhone(contact, this.callingCode)
          : contact.value
      }
      this.contacts = contacts
    },
    addContact () {
      this.contacts.push({
        id: Math.random() * -10,
        type: '',
        value: null,
        isNew: true,
      })
    },
    removeContact (contact) {
      this.contacts.splice(contact, 1)
    },
    submit () {
      const contacts = this.contacts.slice(0)
      let newContacts = []
      for (const contact of contacts) {
        const c = {}
        if (!contact.isNew) {
          c.id = contact.id
        }
        c.value = this.isPhone(contact.type) ? this.callingCode + contact.masked : contact.masked
        c.type = contact.type.id
        newContacts.push(c)
      }
      newContacts = newContacts.filter(c => c.value !== null || c.value !== '')

      return newContacts
    },
  },
}
</script>
